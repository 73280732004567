import * as React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import FaqCard from "../components/faq-card"

const FAQPage = () => {
  // Fetch all cases at build time
  const { allStrapiFaq } = useStaticQuery(graphql`
    query {
      allStrapiFaq {
        nodes {
          question
          anwser
        }
      }
    }
  `)

  return (
    <>
      {/* SEO */}
      <Seo seo={{ metaTitle: 'Veelgestelde vragen' }}/>
      
      {/* Navigation */}
      <Header/>

      <PageTitle data={{
        title: 'Veelgestelde vragen',
        description: 'Heb je een vraag? Wij hebben het antwoord. Wil je nou iets weten wat hier niet tussen staat? Neem dan gerust contact op.',
        cta: true
      }}/>

      <section className="container mx-auto grid px-6 md:px-0 md:grid-cols-2 gap-6 my-16">
        {allStrapiFaq.nodes.map((faq: any, index: number) => (
          <FaqCard key={index} faq={faq}/>          
        ))}
      </section>

      {/* Footer */}
      <Footer/>
    </>
  )
}

export default FAQPage