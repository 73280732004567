import classNames from "classnames"
import React, { useState } from "react"
import { FiChevronDown } from "react-icons/fi"

const FaqCard = ({faq}: any) => {
  const [faqOpen, setFaqOpen] = useState(false)

  const handleToggle = () => {
    setFaqOpen(!faqOpen)
    console.log(faqOpen);
    
  }

  return (
    <div className="group">
      <div onClick={handleToggle} className="flex items-center justify-between p-6 text-slate-500 bg-brand-blue-light group-hover:text-white group-hover:bg-brand-green transition-colors cursor-pointer">
        {faq.question}
        <FiChevronDown className={classNames('transition-all', {
          'rotate-180': faqOpen
        })}/>
      </div>
      <div className={classNames('p-6 text-slate-500 border-brand-blue-light group-hover:border-brand-green border-2 border-t-0 transition-colors', {
        'hidden': !faqOpen
      })}>
        {faq.anwser}
      </div>
    </div>
  )
}

export default FaqCard